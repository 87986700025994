import React, {useContext, useEffect, useState} from 'react';
import Container from "../components/Container";
import FullScreen from "../components/FullScreen";
import {SorteoService} from "../api/Apiv2";
import {InfoContext} from "../config/InfoContext";
import Button from "../components/Button";
import BlackCard from "../components/BlackCard";
import moment from "moment";
import Loading from "react-loading";
import {useHistory} from "react-router-dom";

function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

const Sorteo = (props) => {

  const [sorteos, setSorteos] = useState([]);
  const [loading, setLoading] = useState(false);

  const {sala} = useContext(InfoContext);
  let history = useHistory();

  useEffect(() => {
    if (isEmpty(sala)) {
      history.push("configuration")
    }

    getData();
  }, []);


  const getData = () => {
    setLoading(true);

    let params = {};
    params.salaId = sala.id;

    SorteoService.getSorteos(params).then(res => {
      setLoading(false);
      setSorteos(res);
    }).catch(error => {
      setLoading(false);
    })
  };

  const deleteWinner = (id) => {
    setLoading(true);
    SorteoService.deleteWinner(id).then(res => {
      setLoading(false);
      getData();
    }).catch(error => {
      setLoading(false);
    })
  };

  const renderTable = () => {
    return <table className="table">
      <thead>
      <tr>
        <th className="text-left">Fecha y hora</th>
        <th className="text-left">Cupon</th>
        <th className="text-left">Premio</th>
        <th className="text-left">Ganador</th>
        <th className="text-center"></th>
      </tr>
      </thead>
      <tbody>
      {sorteos.map(job =>
        <tr style={{margin: "18px"}} key={job.fakeId}>
          <td className="text-left">{moment(job.fechaHora).format("DD/MM HH:mm")}</td>
          <td className="text-left">{job.couponTypeName}</td>
          <td className="text-left">S/ {job.amount}</td>
          <td className="text-left">{job.firstName + " " + job.lastName1}</td>
          <td className="text-center">
            <div className="d-flex flex-row">

              {job.sorteoWinnerId === null ? (
                <Button className="mr-1" title={"Asignar"} compact={true} onClick={() => props.history.push({
                  pathname: '/sorteo-assign',
                  search: `couponTypeId=${job.couponTypeId}&sorteoId=${job.id}`
                })}/>
              ) : (
                <Button title={"Anular"} compact={true} variant={"light"}
                        onClick={() => deleteWinner(job.sorteoWinnerId)}/>
              )}
            </div>
          </td>
        </tr>
      )
      }
      </tbody>
    </table>
  };

  return (
    <FullScreen>
      <Container>
        <div style={{padding: "40px"}}>
          <BlackCard>
            <div style={{padding: "10px"}}>
              {
                loading ? <Loading/> : renderTable()
              }
            </div>
          </BlackCard>
        </div>
      </Container>
    </FullScreen>
  );
};

export default Sorteo;
