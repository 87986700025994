import React, { Component } from "react";
import animationData from "../assets/animation/6341-print.json";
import Lottie from "react-lottie";
import { SwapService } from "../api/Apiv2";
import queryString from "query-string";

class Redeem extends Component {
  componentDidMount() {
    const printer = JSON.parse(localStorage.getItem("printer"));
    const values = queryString.parse(this.props.location.search);
    const params = {
      customerId: values.customerId,
      productId: values.productId,
      salaId: values.salaId,
      printerId: printer.printerId,
      computerId: printer.computerId,
    };
    this.createRedeem(params);
    this.monitorPrintRedeem();
  }

  monitorPrintRedeem() {
    this.ii = setInterval(10);
  }

  createRedeem(params) {
    setTimeout(() => {
      SwapService.saveSwapPost(params)
        .then((res) => {
          this.props.history.push("/success");
        })
        .catch((error) => {
          console.log(error);
          this.props.history.push("/error");
        });
    }, "5000");
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        <div style={{ color: "black" }}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
        <h1>CANJE DEL PRODUCTO EN CURSO</h1>
      </div>
    );
  }
}

export default Redeem;
