const baseIp = 'https://api4.wargos.cloud'
const api5 = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({
  BASE: baseIp,
  SORTEOS: `${baseIp}/xsorteos`,
  PC: `${baseIp}/xpointcore`,
  CANJE: `${api5}/xcanje`,
  USER: `${api5}/xuser`,
  PRINTER: `${api5}/xprint`,
});
