import React, {useContext, useEffect, useRef, useState} from 'react';
import Container from "../components/Container";
import FullScreen from "../components/FullScreen";
import {CouponServicev2, SorteoService} from "../api/Apiv2";
import {InfoContext} from "../config/InfoContext";
import Button from "../components/Button";
import BlackCard from "../components/BlackCard";
import Lottie from "react-lottie";
import animationData from "../assets/animation/2837-trophy-animation";
import moment from "moment";
import Loading from "react-loading";
import queryString from "query-string";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const SorteoAssign = (props) => {

  // const [couponId, setCouponId] = useState('3026847');
  const [couponId, setCouponId] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const {sala} = useContext(InfoContext);

  const sorteoId = useRef();
  const couponTypeId = useRef();


  useEffect(() => {
    const values = queryString.parse(props.location.search);
    console.log(values);
    sorteoId.current = values.sorteoId;
    couponTypeId.current = values.couponTypeId;
    //getData();
  }, []);


  const validateDto = () => {
    let params = {};
    params.salaId = sala.id;

    setLoading(true);
    CouponServicev2.validateCoupon(couponId, params).then(res => {
      setLoading(false);
      setData(res);

      if (res.couponTypeId === couponTypeId.current) {
        setValid(true);
      } else {
        setValid(false);
      }

    }).catch(error => {
      setData(null);
      setLoading(false);
      MySwal.fire({
        title: error,
        icon: 'error',
      });
    })
  };

  const saveSorteWinner = () => {
    let params = {};
    params.sorteoId = sorteoId.current;
    params.customerId = data.customerId;
    params.salaId = sala.id;
    SorteoService.saveSorteoWinner(params).then(res => {
      props.history.goBack();
    }).catch(error => {

    });
  };


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const validCoupon = () => {
    return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <span>¡Cupon valido!</span>
      <div style={{color: 'black'}}>
        <Lottie options={defaultOptions}
                height={260}
                width={260}/>
      </div>

      <Button title={"Guardar"} compact={false} onClick={saveSorteWinner}/>
    </div>
  };

  const invalidCoupon = () => {
    return (
      <div>
        <span>Cupon invalido</span>
        <Button title={"Regresar"} onClick={() => props.history.goBack()}/>
      </div>
    )
  };

  return (
    <FullScreen>
      <Container>
        <div style={{padding: "40px"}}>
          <BlackCard>
            <div className="d-flex flex-column" style={{padding: "26px", alignItems: "center"}}>

              <div className="d-flex flex-row">
                <input className="input mr-2" type="text" value={couponId}
                       placeholder={"Codigo de cupon"}
                       onChange={(e) => setCouponId(e.target.value)}/>
                <Button title={"Validar"} compact={false} onClick={() => validateDto()}/>
              </div>

              {
                loading ? <Loading/> : (
                  data !== null && (
                    <div className="d-flex flex-column"
                         style={{marginTop: "20px", alignItems: "center", fontSize: "30px"}}>
                      <span>Cupon : {data.couponTypeName}</span>
                      <span>Cliente : {data.firstName + ' ' + data.lastName1}</span>
                      <span>Fecha de acumulacion : {moment(data.createdAt).format("DD/MM HH:mm")}</span>

                      {valid ? validCoupon() : invalidCoupon()}
                    </div>
                  )
                )
              }
            </div>
          </BlackCard>
        </div>
      </Container>
    </FullScreen>
  );
};

export default SorteoAssign;
