import axios from 'axios';
import {Apis} from "./Apis";

export const ApiService = {

  couponSummary(params = {}) {
    const path = '/coupons/summary';
    return axios.get(Apis.CANJE  + path, {params: params});
  },

};

export const CouponService = {

  async getCouponPrintHistory(params = {}) {
    const path = '/coupon-print-job/last';
    //let params = {};
    //params.customerId = customerId;
    //params.couponTypeId = couponTypeId;
    let res = await axios.get(Apis.PC + path, {params: params});
    return res.data;
  },

  async getCouponTypeActive(salaId){
    const path = '/coupon-type/active';
    let res = await axios.get(Apis.PC + path, {params: {salaId}});
    return res.data;
  },

  async buildAndPrint(couponPrintJobForm) {
    const path = '/coupon-print-job/build-n-print';
    let res = await axios.post( Apis.PC + path, couponPrintJobForm);
    return res.data;
  },

  async checkState(couponPrintJobId) {
    const path = `/coupon-print-job/${couponPrintJobId}/state/v2`;
    let res = await axios.get(Apis.PC + path);
    return res.data;
  },

  async retryCouponPrintJob(couponPrintJobId) {
    const path = `/coupon-print-job/${couponPrintJobId}/retry`;
    let res = await axios.post(Apis.PC + path);
    return res.data;
  }
};

// export class CouponPrintJobForm{
//     customerId;
//     couponTypeId;
//     quantity;
//     computerId;
//      printerId;
// }
