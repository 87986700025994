import React, {useContext} from "react";
import Button from "../components/Button";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {InfoContext} from "../config/InfoContext";

const Buttons = styled.div`
  display: flex;
  height: 100%;
  padding: 2em;
  gap: 3em;
`;

const SuccessfulMessage = () => {
  
  const {setDocument, setData} = useContext(InfoContext);
  let history = useHistory();

  const logout = () => {
    setData(null);
    setDocument("");
    history.push("/customer-login");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4rem",
        }}
      >
        <h1>CANJE EXITOSO</h1>
        <Buttons>
          <Button
            style={{ width: "150px", height: "50px" }}
            title={"SALIR"}
            onClick={logout}
          />
        </Buttons>
      </div>
    </>
  );
};

export default SuccessfulMessage;
