export function getDocumentType() {
  const defaultGlobalConfig = {
    documentType: "DNI",
    showPoints:false,
    showLevel:false,
    showRedeem:false,
    showCardInput:false
  }

  const lsConfig = localStorage.getItem("globalConf")

  if (lsConfig === undefined || lsConfig === null) {
    return defaultGlobalConfig
  }

  let result = {}
  try {
    result = JSON.parse(lsConfig)
  } catch (e) {
    return defaultGlobalConfig
  }


  return {documentType: result.documentType, showPoints: result.showPoints,showLevel: result.showLevel,showRedeem: result.showRedeem, showCardInput: result.showCardInput}
}
