import React from 'react';
import styles from './styles/Coupon.module.css'
import Button from "./Button";
import image from '../assets/img/cuponticket.png';
import {CardStyle} from "./BlackCard";
import {ProgressBar} from "react-bootstrap";
import styled, {keyframes} from "styled-components";

const gradient = keyframes`
	0% {
		background-position:100%;
	}

	100% {
		background-position:0%;
	}
`
const TitleCoupon = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 90px;
`

export const CustomProgressBar =styled(ProgressBar)`
  background: #d9dbdd;
  box-shadow: 0px 3px 10px 0px #523ce8;
  border-radius:4px;
  width:100%;
  margin-bottom:16px;

  > div {
    background: linear-gradient(90deg, #7735fa, #455ade, #a960e5, #523ce8);
    box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);
    background-size: 300% 100%;
    border-radius:4px;
    animation: ${gradient} 1s linear infinite;
    text-align:center;
    max-width:100%;
  }
`
const CouponBanner = ({ coupon, totalPrint, partialPrint, totalDeposit, partialDeposit, loading }) => {

  return (
    <CardStyle style={{width:'320px'}}>
       {
          (coupon.multiplier > 1) && (
            <div className={styles.promoBanner}>
              Ahora multiplica x {coupon.multiplier}
            </div>
          )
        }
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem', padding: '1rem',height: '100%',width:'100%'}}>
          <TitleCoupon>{coupon.couponName}</TitleCoupon>
          
          <span>{coupon.autoPrint ? "Acumulados" : "Disponibles"}</span>
          <div style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center'}}>
            <span style={{ fontSize: "3rem", fontWeight: "bold" }}>{coupon.disponibles}</span>
            <img src={image} alt="coupon img" width="60" />
          </div>
          {coupon.percentage > 0 &&
            <CustomProgressBar animated now={coupon.percentage} label={coupon.percentage +'%'} />
          }
          
          <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            {coupon.autoPrint ?
              <strong style={{paddingTop:'16px'}}>IMPRESO AUTOMATICAMENTE</strong>
              :
              <>
                {coupon.disponibles > 0 && coupon.modulePrint &&
                  <>
                    <Button title={"Seleccionar cantidad"} variant={'light'} onClick={partialPrint} loading={loading} />
                    <Button title={"Imprimir todo"} onClick={totalPrint} loading={loading} />
                  </>
                }
              </>
            }
          </div>
        </div>
    </CardStyle>

  );
};

export default CouponBanner;
