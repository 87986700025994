import React from 'react';
import styles from './styles/Select.module.css'

const Select = ({children, onSelectChange}) => {

  //On the change event for the select box pass the selected value back to the parent
  const handleChange = (event) => {
    event.preventDefault();
    console.log(event);
    const selectedValue = event.target.value;

    onSelectChange(selectedValue);
  };

  return (
    <select className={styles.select} onChange={handleChange}>
      {children}
    </select>
  );
};

export default Select;
