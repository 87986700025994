import React from 'react';
import styles from './styles/Button.module.css'
import cx from 'classnames';
import Loading from "react-loading";

const Button = (props) => {

  const btnClassName = cx(styles.btn,  props.className, {
      [styles.default]: (props.variant === undefined),
      // [styles.default]:  (this.props.variant === 'default') ,
      [styles.light]: (props.variant === 'light'),
      [styles.compact]: (props.compact === true),

    }
  );

  return (
    <button className={btnClassName} onClick={props.onClick} style={props.style} disabled={props.loading} type={props.type}>
      {/*{props.title ? props.title : props.children}*/}
      { props.loading ? <Loading/> : props.title}
    </button>
  );
};


export default Button;
