import React from 'react';
import styled from "styled-components";

export const CardStyle = styled.div`
  background:#1A1A1A;
  border-radius: 1rem;
  color: white;
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 60%) 0px 0px 10px 2px;
  transition: all .5s;
`

const BlackCard = (props) => {

  const cardStyle = {
    background: "#1A1A1A",
    borderRadius: "1rem",
    color: "white",
    boxSizing: "border-box",
    boxShadow: "rgb(0 0 0 / 60%) 0px 0px 10px 2px",
    transition: "all .5s"
  };

  return (
    <div style={cardStyle}>
      {props.children}
    </div>
  );
};

export default BlackCard;
