export function numberFotmat(valor) {
  const numero = Number(valor);

  if (!isNaN(numero)) {
    return numero.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return '--';
  }
}