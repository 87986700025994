import React from "react";
import Routes from "./routes/Routes";
import "./index.css";
import "./styles/util.css";
import WauthProvider from "./auth/WauthProvider";
import axios from "axios";

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth");
  const sala = JSON.parse(localStorage.getItem("sala"));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (sala && sala.id > 0) {
    config.headers.common["X-Sala-ID"] = sala.id;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (401 === error.response.status) {
        window.location = "/logout";
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

function App() {
  return (
    <WauthProvider
      domain={process.env.REACT_APP_AUTH_SERVER}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirectUri={window.location.origin + "/callback"}
    >
      <Routes />
    </WauthProvider>
  );
}

export default App;
