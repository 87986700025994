import axios from 'axios';
import {Apis} from "./Apis";
//import Raven from 'raven-js';
//import store from '../store/index';

//https://laravel-news.com/building-flexible-axios-clients
const getClient = (baseUrl = null) => {

  const options = {
    baseURL: baseUrl
  };

  const client = axios.create(options);

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {

      let errorMessage = "";

      if (!error.response) {
        // network error
        errorMessage = 'Verifique su conexion a internet';
      } else {

        if (error.response.status >= 500) {
          //sentry por observavility porpuses
          //Raven.captureException(error);
        }

        errorMessage = error.response.data.message;
      }

      return Promise.reject(errorMessage);
    },
  );

  return client;
};

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }

  get(url, conf = {}) {
    return this.client.get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client.delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  head(url, conf = {}) {
    return this.client.head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client.options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client.post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  put(url, data = {}, conf = {}) {
    return this.client.put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  patch(url, data = {}, conf = {}) {
    return this.client.patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}

export {ApiClient};

const apiClient = new ApiClient(process.env.REACT_APP_BASE_IP);
//const apiClientPC = new ApiClient(process.env.REACT_APP_BASE_POINTCORE);
//const apiClientSor = new ApiClient(process.env.REACTREACT_APP_BASE_SOR_APP_BASE_SOR);

export const ApiServicev2 = {

  couponSummary(params = {}) {
    const path = '/coupons/summary';
    return apiClient.get(path, {params: params});
  },

  // deprecated
  getSalas() {
    const path = '/salas';
    return apiClient.get(path);
  },

  getPrinters(salaId) {
    const path = '/printers/state-report';
    let params = {
      accountId: salaId
    };
    return apiClient.get(Apis.PRINTER + path, {params: params});
  },
};

export const CouponServicev2 = {

  async getCouponPrintHistory(params = {}) {
    const path = '/coupon-print-job/last';
    let res = await axios.get(Apis.PC + path, {params: params});
    return res.data;
  },

  async getCouponTypeActive(salaId) {
    const path = '/coupon-type/active';
    let res = await axios.get(Apis.PC + path, {params: {salaId}});
    return res.data;
  },

  async buildAndPrint(couponPrintJobForm) {
    const path = '/coupon-print-job/build-n-print';
    let res = await axios.post(Apis.PC + path, couponPrintJobForm);
    return res.data;
  },

  async checkState(couponPrintJobId) {
    const path = `/coupon-print-job/${couponPrintJobId}/state/v2`;
    let res = await axios.get(Apis.PC + path);
    return res.data;
  },

  async retryCouponPrintJob(couponPrintJobId) {
    const path = `/coupon-print-job/${couponPrintJobId}/retry`;
    let res = await axios.post(Apis.PC + path);
    return res.data;
  },
  async validateCoupon(couponId, params) {
    const path = '/coupon/' + couponId + '/validate-dto';
    let res = await axios.get(Apis.PC + path, {params: params});
    return res.data;
  },
};

export const SorteoService = {
  async getSorteos(params = {}) {
    const path = '/sorteos';
    let res = await axios.get(Apis.SORTEOS + path, {params: params});
    return res.data;
  },

  async saveSorteoWinner(params) {
    const path = '/sorteo-winner';
    let res = await axios.post(Apis.SORTEOS + path, params);
    return res.data;
  },

  async deleteWinner(sorteoWinnerId) {
    const path = '/sorteo-winner/' + sorteoWinnerId;
    let res = await axios.delete(Apis.SORTEOS + path);
    return res.data;
  },
};

export const SwapService ={

  async saveSwapPost(params) {
    const path = `/redeems`;
    let res = await axios.post(Apis.PC + path, params);
    return res.data;
  },
}
