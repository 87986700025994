import React, {useContext} from "react";
import Button from "../components/Button";
import {Link, Redirect, Route, useHistory} from "react-router-dom";
import CouponList, {PointsContainer} from "./subpages/CouponList";
import CouponHistory from "./subpages/CouponHistory";
import ConfigConfirmation from "./subpages/ConfigConfirmation";
import SwapList from "./subpages/SwapList";
import {InfoContext} from "../config/InfoContext";
import Container from "../components/Container";
import Anfora from "./Anfora";
import styled from "styled-components";
import dreamsHomeBg from "../assets/img/Fondo-AppDreams-2.jpg";
import "../styles/Login.css";
import Swap from "./subpages/Swap"
import {getDocumentType} from "../components/services"
import {BiDiamond} from "react-icons/bi";
import {FaStar} from "react-icons/fa";
import moment from "moment";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-top: 1rem;
`
const Title = styled.span`
  color: #dcdfe5;
  font-size: 2.3vw;
  text-align: center;
  font-weight: bold;
`
const NavBarLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BackgroundHome = styled.div`
  width: 100%;
  height: 100vh;
  background-image: ${(props) => props.img};
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: bottom;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: flex-end;
`;

const Home = (props) => {
  let history = useHistory();
  const globalConfig = getDocumentType()
  const showRedeem = globalConfig.showRedeem
  const showPoints = globalConfig.showPoints
  const showLevel = globalConfig.showLevel

  const {sala, printer, setData,data, setDocument} = useContext(InfoContext);

  if (sala === null) {
    return <Redirect to={"/configuration"}/>;
  }

  const logout = () => {
    setData(null);
    setDocument("");
    history.push("/customer-login");
  };

  let homeBackground = "linear-gradient(246deg,#1f3c6a,#178a9e 50%,#1bb4b7)";

  if (window.location.host === "dreams.kiosko.wargos.cloud") {
    homeBackground = `url(${dreamsHomeBg})`;
  }

  return (
    <>
      <BackgroundHome img={homeBackground}>
        <Container>
          <NavBar>
            <NavBarLogo>
              <Title>{sala.name}</Title>
              {printer !== null && (
                <Link
                  to="/home/config-confirmation"
                  style={{fontSize: "15px", color: "black"}}
                >
                  Imp: {printer.printerName}
                </Link>
              )}
            </NavBarLogo>
            <div>
              <Link to="/home/coupon">
                <Button style={{marginRight: "12px"}} title={"Cupones"}/>
              </Link>
              <Link to="/home/coupon-history">
                <Button style={{marginRight: "12px"}} title={"Historial"}/>
              </Link>
              {showRedeem &&
                <Link to="/home/swap-list">
                  <Button style={{marginRight: "12px"}} title={"Canjes"}/>
                </Link>
              }
              <Button title={"Salir"} onClick={logout}/>
            </div>
          </NavBar>

          <div style={{padding:'1rem 2rem 1rem 1rem',marginTop:'0.5rem',borderRadius:'16px',background:'rgb(255 255 255 / 25%)',width:'fit-content'}}>
            <span style={{fontWeight:'bold',fontSize:'1.2rem'}}>
              {data.person.firstName + " " + data.person.lastName1}
            </span>
            {showLevel &&
              <>
                {data.levelId > 0 &&
                  <PointsContainer>
                    <span style={{fontSize:'1.3rem'}}>
                      Nivel: <strong>{data.levelName}</strong>
                    </span>
                    <BiDiamond size={28} />
                  </PointsContainer>
                }
              </>
            }
            {showPoints &&
              <PointsContainer>
                <span style={{fontSize:'1.3rem'}}>
                  Puntos disponibles: <strong>{Math.trunc(data.pointsRedeemable).toLocaleString("en-US")} </strong>
                </span>
                <FaStar size={'20px'} />
              </PointsContainer>
            }
          </div>

          <div style={{paddingTop: "1rem",flex:1}}>
            <Route path="/home/coupon" component={CouponList}/>
            <Route path="/home/coupon-history" component={CouponHistory}/>
            <Route path="/home/swap-list" component={SwapList}/>
            <Route path="/home/anfora" component={Anfora}/>
            <Route path="/home/swap" component={Swap}/>
            <Route
              path="/home/config-confirmation"
              component={ConfigConfirmation}
            />
          </div>
          <Footer>
            <span>Actualizado hasta {moment(data.fecha).format("DD/MM hh:mm a")}</span>
          </Footer>
        </Container>
      </BackgroundHome>
    </>
  );
};

export default Home;
