import React from "react";
import Button from "../components/Button";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Buttons = styled.div`
  display: flex;
  height: 100%;
  padding: 2em;
  gap: 3em;
`;

const ErrorMessage = () => {
  let history = useHistory();

  const goBackSwap = () => {
    history.push('/home/swap-list')
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4rem",
        }}
      >
        <h1>Hubo un error inténtalo de nuevo</h1>
        <Buttons>
          <Button
            style={{
              width: "150px",
              height: "50px",
              backgroundColor: "white",
              color: "#cc0025",
            }}
            title={"REGRESAR"}
            onClick={goBackSwap}
          />
        </Buttons>
      </div>
    </>
  );
};

export default ErrorMessage;
