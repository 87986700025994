import React from 'react';
import styles from './styles/FullScreen.module.css'

const FullScreen = (props) => {
  return (
    <div className={styles.main}>
      {props.children}
    </div>
  );
};

export default FullScreen;
