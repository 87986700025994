import React,{useState} from 'react'
import Button from '../components/Button'

import anfora from '../assets/img/anfora.svg'
import cupon from '../assets/img/cupon.svg'


const Anfora = () => {

  return (
    <div style={{display:"flex", flexDirection:"column",alignItems:"center",width:"100%"}}>
      
      <div style={{display:"flex", flexDirection:"column",alignItems:"center",margin:"1rem 0 2rem"}}>
        <div style={{display:"flex", flexDirection:"row",alignItems:"center"}}>
          <img src={cupon} alt="" width="45" height="45" />
          <span style={{fontSize:"3rem",fontWeight:"900",marginLeft:"1rem"}}>
            CUPON VIP
          </span>
        </div>
       

        <span style={{fontSize:"1.5rem",opacity:"0.5",fontWeight:"600"}}>
          CANTIDAD DE CUPONES DISPONIBLES: 500 CUPONES
        </span>
      </div>

      <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%",margin:"1rem 0 2rem"}}>
        <label style={{margin:"0",fontSize:"2rem",fontWeight:"800",textAlign:"center"}}>
          CANTIDAD:
        </label>
        <input type="number" min="0"
          style={{width: "20%", fontSize:"2rem",textAlign:"center",margin:"0 0 0 2rem"}} />
      </div>

      <div style={{display:"flex",flexDirection:"row", width:"100%", justifyContent:"center"}}>

        <div style={{display:"flex",flexDirection:"row",alignItems:"center",margin:"1rem",width:"calc(50% - 2rem)",border: "1px solid #999",borderRadius:"5px",padding:"1rem"}}>
          
          <img src={anfora} alt="" width="80" height="80" />
          <div style={{display:"flex",flexDirection:"column"}}>
            <span style={{fontWeight:"500"}}>ANFORA: <span style={{fontSize:"1.2rem",fontWeight:"bold"}}>REVENTON FIN DE MES</span></span>
            <span style={{fontWeight:"500"}}>CUPONES DEPOSITADOS: <span style={{fontSize:"1.2rem",fontWeight:"bold"}}>400 CUPONES</span></span>
          </div>
         
        </div>

      </div>

      <Button title={"DEPOSITAR"} />

    </div>
  )
}

export default Anfora
