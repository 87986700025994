import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import VerticalSpacing from "../../components/VerticalSpacing";
import "../../styles/Login.css";

const ConfigConfirmation = () => {
	const [clave, setClave] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false)
	let history = useHistory();

	const config = () => {
		if (clave !== "8023") {
			setError(true)
			return;
		}
		history.push("/configuration");
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: "15%",
			}}
		>
			<VerticalSpacing>
				<input
					className="input-dni"
					placeholder="Ingrese la Clave"
					value={clave}
					onChange={(e) => setClave(e.target.value)}
					onKeyPress={event => {
                        if (event.key === 'Enter') {
                            config();
                        }
                    }} 
				/>
			</VerticalSpacing>
			{error? <div style={{marginBottom: "1rem", fontWeight: "bold", fontSize: "20px"}}>Clave incorrecta</div> : null}
			<Button
				onClick={() => config()}
				loading={loading}
				title={"Ir a la configuración"}
			/>
		</div>
	);
};

export default ConfigConfirmation;
