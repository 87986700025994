import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Configuration from "../pages/Configuration";
import ClientLogin from "../pages/ClientLogin";
import Home from "../pages/Home";
import { CustomerProvider } from "../config/Context";
import Printing from "../pages/Printing";
import { InfoProvider } from "../config/InfoContext";
import Sorteo from "../pages/Sorteo";
import SorteoAssign from "../pages/SorteoAssign";
import LoginPage from "../login/LoginPage";
import CallbackPage from "../login/CallbackPage";
import LogoutPage from "../login/LogoutPage";
import PrivateRoute from "../auth/PrivateRoute";
import Swap from "../pages/subpages/Swap";
import SuccessfulMessage from "../pages/SuccessfulMessage";
import ErrorMessage from "../pages/ErrorMessage";
import Redeem from "../pages/Redeem";

//export const CustomerContext = React.createContext({});

const Routes = () => (
  <InfoProvider>
    <CustomerProvider>
      <Switch>
        <PrivateRoute exact path="/customer-login" component={ClientLogin}/>
        <PrivateRoute exact path="/configuration" component={Configuration}/>
        <PrivateRoute exact path="/printing" component={Printing}/>
        <PrivateRoute exact path="/redeem" component={Redeem}/>
        <PrivateRoute exact path="/sorteos" component={Sorteo}/>
        <PrivateRoute exact path="/swap" component={Swap}/>
        <PrivateRoute exact path="/success" component={SuccessfulMessage}/>
        <PrivateRoute exact path="/error" component={ErrorMessage}/>
        <PrivateRoute exact path="/sorteo-assign" component={SorteoAssign}/>
        <PrivateRoute path="/home" component={Home}/>

        <Route exact path="/login" component={LoginPage}/>
        <Route exact path="/callback" component={CallbackPage}/>
        <Route exact path="/logout" component={LogoutPage}/>

        <Route exact path="/">
          <Redirect to="/customer-login"/>
        </Route>
      </Switch>
    </CustomerProvider>
  </InfoProvider>
);

export default Routes;
