import React, {useContext, useEffect, useState} from 'react';
import BlackCard from "../../components/BlackCard";
import Select from "../../components/Select";
import {CouponService} from "../../api/BaseApi";
import Button from "../../components/Button";
import Loading from "react-loading";
import {InfoContext} from "../../config/InfoContext";
import moment from "moment";

const CouponHistory = (props) => {

  const [jobs, setJobs] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  const {sala, data} = useContext(InfoContext);

  useEffect(() => {
    getCouponHistory(null);
    getCouponActive();
  }, []);


  const getCouponHistory = (couponTypeId) => {
    let params = {};
    params.customerId = data.person.id;
    params.couponTypeId = couponTypeId;
    params.salaId = sala.id;

    setLoading(true);
    CouponService.getCouponPrintHistory(params).then(res => {
      setLoading(false);
      setJobs(res);
    }).catch(error => {
      setLoading(false);
    })
  };

  const getCouponActive = () => {
    CouponService.getCouponTypeActive(sala.id).then(res => {
      //setLoading(false);
      setCoupons(res);
    }).catch(error => {
      //setLoading(false);
    });
  };

  const retry = (job) => {
    CouponService.retryCouponPrintJob(job.couponPrintJobId).then(res => {

      props.history.push({
        pathname: '/printing',
        search: `?quantity=${job.quantity}&couponPrintJobId=${job.couponPrintJobId}`
      });
    }).catch(error => {

    });
  };

  const onCouponChange = (couponTypeId) => {
    getCouponHistory(couponTypeId);
  };

  const renderTable = () => {
    return <table className="table">
      <thead>
      <tr>
        <th className="text-left">Cupon</th>
        <th className="text-center">Impresos</th>
        <th className="text-center">Estado</th>
        <th className="text-center">Fecha</th>
        <th className="text-center"></th>
      </tr>
      </thead>
      <tbody>
      {jobs.map(job =>
        <tr key={job.couponPrintJobId}>
          <td className="text-left">{job.couponName}</td>
          <td className="text-center">{job.avance + '  /  ' + job.quantity}</td>
          <td className="text-center">{job.state}</td>
          <td className="text-center">{moment(job.createdAt).format("DD/MM HH:mm")}</td>
          <td className="text-center">
            {/*{(job.complete === true && job.state === 'ERROR') && <Button title={"Reintentar"} variant={'light'} compact={true} onClick={() => retry(job)}  /> }*/}
            {(job.state === 'ERROR') &&
              <Button title={"Reintentar"} variant={'light'} compact={true} onClick={() => retry(job)}/>}
          </td>
        </tr>
      )
      }
      </tbody>
    </table>
  };

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <BlackCard>
        <div style={{padding:'16px'}}>
          <h4 style={{padding: '1rem'}}>Seleccione una promocion :</h4>
          <Select onSelectChange={onCouponChange}>
            <option value="0">TODOS LOS CUPONES</option>
            {coupons.map((coupon, index) =>
              <option key={index} value={coupon.id}>{coupon.name}</option>
            )}
          </Select>

          <div className="mt-2 scrollbar" style={{height: '300px',overflowY: 'auto'}}>
            {!loading ? (
                jobs.length > 0 ? renderTable() : <span>Sin registros</span>
              )
              : <Loading/>}
          </div>

        </div>
      </BlackCard>
    </div>
  );
};

//CouponHistory.contextType = InfoContext;
export default CouponHistory;
