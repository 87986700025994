import React, { Component } from "react";
import Button from "../components/Button";
import { CouponServicev2 } from "../api/Apiv2";
import queryString from "query-string";
import animationData from "../assets/animation/6341-print.json";
import Lottie from "react-lottie";

class Printing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      printStatus: "ENVIANDO",
      errorMessage: "",
      warningMessage: "",
      avance: "-",
      quantity: "-",
      currentRetries: 0,
      finished: false,
      retryEnabled: false,
      isStopped: false,
    };
  }

  resetState() {
    this.setState({
      printStatus: "ENVIANDO",
      errorMessage: "",
      warningMessage: "",
      avance: "-",
      quantity: "-",
      currentRetries: 0,
      finished: false,
      retryEnabled: false,
    });
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.couponPrintJobId = values.couponPrintJobId;
    this.setState({ quantity: values.quantity });

    this.checkState();
    this.monitorPrintState();
  }

  monitorPrintState() {
    this.ii = setInterval(() => this.checkState(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.ii);
  }

  checkState() {
    //console.log(values.quantity);
    CouponServicev2.checkState(this.couponPrintJobId).then((data) => {
      let finished = false;
      let retryEnabled = false;
      let currentRetries = data.currentRetries;

      let quantity = data.quantity;
      let avance = data.avance;

      if (quantity === avance) {
        finished = true;
      }
      let state = data.state;
      let warningMessage = "";
      let errorMessage = "";
      let printStatus = this.state.printStatus;

      if (state === "SENT_TO_CLIENT") {
        printStatus = "PROCESANDO";
      } else if (state === "QUEUED") {
        //aca se podria leer , si el papel se esta agotando
        printStatus = "IMPRIMIENDO";
        warningMessage = data.message;
      } else if (state === "ERROR") {
        printStatus = "ERROR";
        errorMessage = data.message;
      } else if (state === "DONE") {
        printStatus = "FINALIZADO";
      }

      if (data.complete) {
        finished = true;
        clearInterval(this.ii);
        if (state === "ERROR") {
          retryEnabled = true;
        }
      }

      this.setState({
        printStatus,
        errorMessage,
        warningMessage,
        quantity,
        avance,
        retryEnabled,
        currentRetries,
        finished,
      });
    });
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4rem",
        }}
      >
        <h2>{this.state.printStatus}</h2>
        <h3 className="text-red">{this.state.errorMessage}</h3>
        <h3 className="text-orange">{this.state.warningMessage}</h3>
        <h1>
          {this.state.avance} / {this.state.quantity}
        </h1>

        {this.state.currentRetries > 1 && (
          <h1>Reintento : {this.state.currentRetries}</h1>
        )}
        {this.state.finished ? (
          <>
            <div className="mt-4">
              <Button
                title={"Inicio"}
                variant={"light"}
                onClick={() => {
                  this.props.history.push("home/coupon");
                }}
              />
            </div>
            <div className="mt-3">
              <Button
                title={"Salir"}
                onClick={() => {
                  //clear store
                  this.props.history.push("customer-login");
                }}
              />
            </div>
          </>
        ) : (
          <div style={{ color: "black" }}>
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        )}
        {this.state.retryEnabled && (
          <div className="mt-4">
            <Button
              title={"Reintentar"}
              onClick={() =>
                CouponServicev2.retryCouponPrintJob(this.couponPrintJobId).then(
                  (res) => {
                    this.monitorPrintState();
                    this.resetState();
                  }
                )
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default Printing;
