import BlackCard from "../components/BlackCard";
import {CustomerConsumer} from "../config/Context";
import Button from "../components/Button";
import Select from "../components/Select";
import {ApiServicev2} from "../api/Apiv2";
import stylesSelect from '../components/styles/Select.module.css'
import React, {useContext, useEffect, useState} from "react";
import {InfoContext} from "../config/InfoContext";
import useFetch from "../hooks/useFetch";
import {Apis} from "../api/Apis";

const Configuration = (props) => {

  const [salas, setSalas] = useState([]);
  const [printers, setPrinters] = useState([]);
  //const [selectedSala, setSelectedSala] = useState(JSON.parse(localStorage.getItem('sala')) || {});
  //const [selectedPrinter, setSelectedPrinter] = useState(JSON.parse(localStorage.getItem('printer')) || {});

  const {sala, setSala, printer, setPrinter} = useContext(InfoContext);

  const {loading, data} = useFetch(Apis.USER + '/users/me', null)

  useEffect(() => {
    if (data) {
      setSalas(data.salas);
    }
  }, [data]);

  useEffect(() => {
    if (sala !== null) {
      getPrinters(sala.id);
    }
  }, [sala]);

  useEffect(() => {
    setPrinter(printer);
  }, [printer]);


  const getPrinters = (printerId) => {
    if (printerId > 0) {
      ApiServicev2.getPrinters(printerId).then(res => {
        setPrinters(res.data);
      });
    }
  };

  const handleChangeSala = (value) => {
    const sala = salas[value];
    if (sala !== undefined) {
      setSala(sala);
    }
  };

  const handleChangePrinter = (value) => {
    const printer = printers[value];
    setPrinter(printer);
  };


  const formDiv = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem"
  };

  const title = {
    fontSize: "22px",
    color: "white",
    marginBottom: "1rem"
  };

  return (
    <CustomerConsumer>
      {store =>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh"
        }}>
          <BlackCard>
            {/*<div>{JSON.stringify(selectedSala)}</div>*/}
            {/*<div>{JSON.stringify(selectedPrinter)}</div>*/}
            <div style={{display: 'flex', flexDirection: 'column', width: "400px", padding: "20px"}}>
              <div style={formDiv}>
                <label style={title}>Seleccione la sala</label>
                <Select styles={stylesSelect.select} onSelectChange={handleChangeSala}>
                <option value={null}>SELECCIONE UNA SALA</option>
                  {salas && salas.map((data, index) =>
                    <option key={index} value={index}> {data.name} </option>
                  )}
                </Select>
              </div>

              <div style={formDiv}>
                <label style={title}>Seleccione el terminal</label>
                <Select onSelectChange={handleChangePrinter}>
                  <option value={null}>SELECCIONE UNA IMPRESORA</option>
                  {printers && printers.map((data, index) =>
                    <option key={index} value={index}>{data.hostname + ' | ' + data.printerName}</option>
                  )}
                </Select>
              </div>

              <Button title={"Guardar"} disabled onClick={() => props.history.push('customer-login')}/>
            </div>
          </BlackCard>
        </div>
      }
    </CustomerConsumer>
  );

};

export default Configuration;
