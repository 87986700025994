import React, {useReducer, useEffect} from "react"
import globalConfSala from '../assets/dataSala/globalConfig.json' 

const reducer = (info, newInfo) => {
    if (newInfo === null) {
        localStorage.removeItem("sala");
        return initialState;
    }
    return newInfo;
};

const reducer2 = (info, newInfo) => {
    if (newInfo === null) {
        localStorage.removeItem("printer");
        return initialState;
    }
    return newInfo;
};

const reducer3 = (info, newInfo) => {
    if (newInfo === null) {
        localStorage.removeItem("data");
        return initialState;
    }
    return newInfo;
};

const reducer4 = (info, newInfo) => {
    if (newInfo === null) {
        localStorage.removeItem("document");
        return initialState;
    }
    return newInfo;
};

const reducer5 = (info, newInfo) => {
    if (newInfo === null) {
        localStorage.removeItem("card");
        return initialState;
    }
    return newInfo;
};

const initialState = null;
const localSala = JSON.parse(localStorage.getItem("sala"));
const localPrinter = JSON.parse(localStorage.getItem("printer"));
const localData = JSON.parse(localStorage.getItem("data"));
const localDocument = localStorage.getItem("document");
const localCard = localStorage.getItem("card");

const InfoContext = React.createContext();

function InfoProvider(props) {

    const [sala, setSala] = useReducer(reducer, localSala || initialState);
    const [printer, setPrinter] = useReducer(reducer2, localPrinter || initialState);
    const [data, setData] = useReducer(reducer3, localData || initialState);
    const [document, setDocument] = useReducer(reducer4, localDocument || initialState);
    const [card, setCard] = useReducer(reducer5, localCard || initialState);

    useEffect(() => {
        if (sala !== null) {
            localStorage.setItem("sala", JSON.stringify(sala));
            localStorage.setItem('globalConf', JSON.stringify(globalConfSala.filter(item => item.salaId === sala.id)[0]))
        }
    }, [sala]);

    useEffect(() => {
        if (printer !== null) {
            localStorage.setItem("printer", JSON.stringify(printer));
        }
    }, [printer]);

    useEffect(() => {
        if (data !== null) {
            localStorage.setItem("data", JSON.stringify(data));
        }
    }, [data]);

    useEffect(() => {
        if (document !== null) {
            localStorage.setItem("document", document);
        }
    }, [document]);

    useEffect(() => {
        if (card !== null) {
            localStorage.setItem("card", card);
        }
    }, [card]);

    return (
        <InfoContext.Provider value={{sala, setSala, printer, setPrinter, data, setData, document, setDocument,card,setCard}}>
            {props.children}
        </InfoContext.Provider>
    );
}

export {InfoContext, InfoProvider};
