import React, {useContext} from "react";
import Button from "../../components/Button";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../api/Apis";
import {InfoContext} from "../../config/InfoContext";
import {useHistory} from "react-router-dom";
import {numberFotmat} from "../../utils/utils";

const SwapContainer = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  gap: 5%;
`;
const SwapInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  height: 100%;
  padding: 32px 16px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  gap: 1em;

  h1 {
    font-size: 24px;
  }

  img {
    width: 150px;
    height: 150px;
  }
`;
const SwapDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3em;
  text-align: left;
  width: 65%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  gap: 1em;

  h1 {
    font-size: 20px;
  }

`;

const Swap = () => {

  const {sala, data} = useContext(InfoContext);

  const querystring = window.location.search;
  const query = new URLSearchParams(querystring);
  let customerId = query.get("customerId");
  let productId = query.get("productId");
  let couponTypeId = query.get("couponTypeId");
  couponTypeId = couponTypeId === 'null' ? null : couponTypeId;

  const {loading, data: dataProduct} = useFetch(Apis.PC + `/catalog-products/${productId}`, []);

  let history = useHistory();
  const goSwapList = () => {
    history.goBack();
  };

  const saveSwap = () => {
    history.push({
      pathname: "/redeem",
      search: `?customerId=${customerId}&productId=${productId}&salaId=${sala.id}`,
    });
  };

  let couponRedeem = data.couponList.find(coupon => coupon.couponId === Number(couponTypeId))

  let customerHasPoints = (data.pointsRedeemable * (data.pointValue === 0 ? 1 : data.pointValue)) - dataProduct.point;
  let customerHasCoupons = couponRedeem?.disponibles - dataProduct.point;

  return (
    <SwapContainer>
      {loading ?
        <div>Cargando...</div>
        :
        <>
          <SwapInformation>
            {dataProduct && (
              <>
                <h1>{dataProduct.name}</h1>
                <img
                  src={`${process.env.REACT_APP_S3_WARGOS_ASSETS}${dataProduct.photoUrl}`}
                  alt="imagen"
                  style={{width: "auto", maxHeight: "200px"}}
                />
                <h2>
                  {couponTypeId !== null ?
                    numberFotmat(dataProduct.point) + ' CUPONES'
                    :
                    numberFotmat(dataProduct.point * (data.pointValue === 0 ? 1 : data.pointValue)) + ' PUNTOS'
                  }
                </h2>
              </>
            )}
          </SwapInformation>

          <SwapDetails>
            {dataProduct &&
              <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <h1 style={{flex: 1}}>DISPONIBLES</h1>
                  <h1>
                    {couponTypeId !== null ?
                      numberFotmat(couponRedeem?.disponibles) + ' CUPONES'
                      :
                      numberFotmat(data.pointsRedeemable) + ' PUNTOS'
                    }
                  </h1>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <h1 style={{flex: 1}}>{dataProduct.name}</h1>
                  <h1>
                    - {couponTypeId !== null ?
                      numberFotmat(dataProduct.point) + ' CUPONES'
                      :
                      numberFotmat(dataProduct.point * (data.pointValue === 0 ? 1 : data.pointValue)) + ' PUNTOS'
                    }
                  </h1>
                </div>

                <hr style={{color: "black", width: "100%"}} />

                {(couponTypeId !== null ? customerHasCoupons > 0 : customerHasPoints > 0) ?
                  <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <h1 style={{flex: 1}}>RESTANTE</h1>
                      <h1>
                        {couponTypeId !== null ? numberFotmat(customerHasCoupons) : numberFotmat(customerHasPoints)}
                      </h1>
                    </div>
                    <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-evenly'}}>
                      <Button style={{backgroundColor: "white", color: "#cc0025"}}
                        title={"REGRESAR"}
                        onClick={goSwapList}
                      />
                      <Button title={"CANJEAR"} onClick={saveSwap} />
                    </div>
                  </div>
                  :
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px'}}>
                    <h1 style={{textAlign: "center"}}>
                      NO TIENES LA CANTIDAD SUFICIENTE PARA CANJEAR ESTE PRODUCTO
                    </h1>
                    <Button title={"REGRESAR"} onClick={goSwapList} />
                  </div>
                }
              </div>
            }
          </SwapDetails>
        </>
      }
    </SwapContainer>
  );
};

export default Swap;
