import React, {Component} from 'react';

//https://medium.com/@chrispearce/glad-you-liked-it-98f13d467a0a
class VerticalSpacing extends Component {
  render() {
    return (
      <div className="d-flex flex-column mb-1">
        {this.props.children}
      </div>
    );
  }
}

export default VerticalSpacing;
