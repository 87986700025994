import React, { createContext } from 'react';

export const CustomerContext = createContext({
  data: {},
  //sala : {},
  printer : {},
  updateData: () => {},
});

export class CustomerProvider extends React.Component {

  updateData = data => {
    this.setState({ data: data });
  };

  state = {
    data:  {
      person:
        {"id":1501,"firstName":"JULIO","lastName1":"PAPAYA","lastName2":"SOTOO","documentTypeId":1,"document":"46209468","email":null,"cellphone":"949685428","telephone":null,"countryId":173,"address":null,"gender":"S","civil":"S","photo":null},
      couponList:[
        {"couponId":48,"couponName":"CUPON RULEDERBYN","autoPrint":0,"quantity":0,"impresos":0,"disponibles":0,"cantidad":0,"amountForPoint":0.0,"meterId":0,"multiplier":1,"modulePrint":true,"moduleShow":true,"customEnabled":false},
        {"couponId":49,"couponName":"CUPON ESTELAR","autoPrint":0,"quantity":69,"impresos":61,"disponibles":4,"cantidad":0,"amountForPoint":0.0,"meterId":0,"multiplier":3,"modulePrint":true,"moduleShow":true,"customEnabled":true},
        {"couponId":50,"couponName":"CUPON DIARIO","autoPrint":1,"quantity":21,"impresos":21,"disponibles":0,"cantidad":0,"amountForPoint":0.0,"meterId":0,"multiplier":1,"modulePrint":false,"moduleShow":false,"customEnabled":true},
         // {"couponId":51,"couponName":"PARADA STOP","autoPrint":0,"quantity":12,"impresos":0,"disponibles":12,"cantidad":0,"amountForPoint":0.0,"meterId":0,"multiplier":1,"modulePrint":true,"moduleShow":true,"customEnabled":true}
         ]
      ,fecha:"2020-01-31T20:26:18.59"},
    //sala : { id : "2", name : "STOP BOLO"},
    printer : { id : "9", name : "POSPrinter", computer: { id : 1, name : "MAIN PC"}},
    updateData: this.updateData,
  };

  render() {
    return (
      <CustomerContext.Provider value={this.state}>
        {this.props.children}
      </CustomerContext.Provider>
    );
  }
}

export const CustomerConsumer = CustomerContext.Consumer;
