import React from "react";
import styled from "styled-components";
import Button from "./Button";
import {useHistory} from "react-router-dom";
import {numberFotmat} from "../utils/utils";

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 250px;
  min-width: 250px;
  padding: 24px;
  background: #fff;
  margin-bottom: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  gap: 16px;
  user-select:none;

  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  img {
    max-height:160px;
    max-width:100%;
  }
`;

const CardSwap = ({title, points, id, url, couponTypeId, dataPoints}) => {
  let history = useHistory();

  const goCanje = () => {
    history.push(
      `/home/swap?customerId=${dataPoints.person.id}&productId=${id}&couponTypeId=${couponTypeId}`
    );
  };

  return (
    <CardStyled id={id}>
      <h1>{title}</h1>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "160px"}}>
        <img src={url} alt="imagen"  />
      </div>
      
      <h2>{numberFotmat(points) + (couponTypeId !== null ? ' CUPONES':' PUNTOS')}</h2>
      <Button
        onClick={() => goCanje()}
        title={"CANJEAR"}
      />
    </CardStyled>
  );
};
export default CardSwap;
