import React from 'react';
import styles from './NumberPad.module.css'
import Button from "./Button";

const NumberPad = (props) => {

  const handleClick = (character) => {
    console.log(character);
    let newValue = "" + props.value + "" + character;
    console.log("new value : " + newValue);
    props.onChange(newValue);
  };

  const clearAll = () => {
    this.props.onChange("");
  };

  const backSpace = () => {
    this.props.onChange(props.value.slice(0, -1));
  };

  return (
    <div className={styles.grid}>
      <Button title={"1"} variant={'light'} onClick={() => handleClick(1)}/>
      <Button title={"2"} variant={'light'} onClick={() => handleClick(2)}/>
      <Button title={"3"} variant={'light'} onClick={() => handleClick(3)}/>
      <Button title={"4"} variant={'light'} onClick={() => handleClick(4)}/>
      <Button title={"5"} variant={'light'} onClick={() => handleClick(5)}/>
      <Button title={"6"} variant={'light'} onClick={() => handleClick(6)}/>
      <Button title={"7"} variant={'light'} onClick={() => handleClick(7)}/>
      <Button title={"8"} variant={'light'} onClick={() => handleClick(8)}/>
      <Button title={"9"} variant={'light'} onClick={() => handleClick(9)}/>
      <Button title={"Borrar"} variant={'light'} onClick={() => clearAll()}/>
      <Button title={"0"} variant={'light'} onClick={() => handleClick(0)}/>
      <Button title={"Back"} variant={'light'} onClick={() => backSpace()}/>
    </div>
  );
};

export default NumberPad;
