import React, {useContext, useEffect, useState} from 'react';
import CouponBanner from "../../components/CouponBanner";
import {Redirect, useHistory} from "react-router-dom";
import {ApiServicev2, CouponServicev2} from "../../api/Apiv2";
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2'
import {InfoContext} from "../../config/InfoContext";
import styled from "styled-components"

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-bottom: 1rem;
  overflow-y: auto;
`
const MySwal = withReactContent(Swal);

export const showAlert = (message) => {
  return MySwal.fire({
    title: message,
    icon: 'error',
  })
};

const CouponList = () => {

  const [loading, setLoading] = useState(false);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const history = useHistory();

  const {sala, printer, document,card} = useContext(InfoContext);
  const [data, setData] = useState(null)

  useEffect(() => {
    getCouponData();
  }, []);

  const getCouponData = () => {
    const params = {document: document,card: card, salaId: sala.id};
    //setLoading(true);

    ApiServicev2.couponSummary(params).then(res => {
      setData(res.data);
    }).catch(error => {
      //setLoading(false);
      setData(null);
      showAlert(error);
    });
  }

  if (sala === null) {
    return <Redirect to={'/configuration'}/>
  }


  const partialPrint = async (coupon) => {
    const {value: url} = await Swal.fire({
      title: 'Ingrese la cantidad de cupones',
      input: 'number',
      inputPlaceholder: 'Cantidad'
    });

    if (url) {
      //await Swal.fire(`Entered URL: ${url}`)
      printEvent(coupon, url);
    }
  };

  const totalPrint = async (coupon) => {
    const {value: confirm} = await Swal.fire({
      title: 'Deseas imprimir todos tus cupones ?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    });

    if (confirm) {
      const totalCupones = coupon.disponibles;
      printEvent(coupon, totalCupones);
    }
  };

  const printEvent = (coupon, quantity) => {
    startLoading();

    const params = {
      customerId: data.person.id,
      couponTypeId: coupon.couponId,
      quantity: quantity,
      salaId: sala.id,
      printerId: printer.printerId,
      computerId: printer.computerId
    };

    CouponServicev2.buildAndPrint(params).then(data => {
      history.push({
        pathname: '/printing',
        search: `?quantity=${data.quantity}&couponPrintJobId=${data.id}`
      });
    }).catch(error => {
      stopLoading();
      let errorMessage = 'Se produjo un error al procesar la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      
      MySwal.fire({
        title: errorMessage,
        icon: 'error',
      });
    });
  };

  const partialDeposit = async (coupon) => {
    //TODO
  }

  const totalDeposit = () => {
    //TODO
  }

  return (
    data &&
      <CouponContainer className="scrollbar">
        {data.couponList
          .filter(coupon => coupon.moduleShow)
          .map((coupon) =>
            <div key={coupon.couponId} style={{display: "flex", padding: "0 0.5rem"}}>
              <CouponBanner 
                coupon={coupon}
                totalPrint={() => totalPrint(coupon)}
                partialPrint={() => partialPrint(coupon)}
                totalDeposit={() => totalDeposit(coupon)}
                partialDeposit={() => partialDeposit(coupon)}
                loading={loading}
              />
            </div>
          )}
      </CouponContainer>
  );

};

export default CouponList;
