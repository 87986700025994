import React, {useEffect, useState} from "react";
import {Redirect} from "react-router";
import {useWauth} from "../auth/WauthProvider";

const CallbackPage = () => {

  const {handleAuthentication, isAuthenticated } = useWauth()
  const [message, setMessage] = useState("Verificando cuenta")
  
  let referer = '/configuration'

  useEffect(() => {
    handleAuthentication()
  }, [])


  if (isAuthenticated) {
    
    if(localStorage.sala && localStorage.printer) referer = '/customer-login'
  
    return <Redirect to={referer}/>
  }

  return (
    <div>
      {message}
    </div>
  )
};

export default CallbackPage;
